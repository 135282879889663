import { Controller } from "stimulus"
import $ from 'jquery';

require("bootstrap-select/dist/css/bootstrap-select.min.css")
require("bootstrap-select/dist/js/bootstrap-select.min")
require("bootstrap-select/js/bootstrap-select")

export default class extends Controller {
  static targets = [ "optionDropdown" ]

  connect() {
    let select = $('select')
    select.selectpicker();
  }

  filterByOption() {
    let parentDropdownValue = this.optionDropdownTarget.value
    let $this = $(this.optionDropdownTarget)
    let target = $($this).parent().parent().find('.nested-option-value-dropdown')
    let $final_target = $(target.prevObject.parent().find('select.nested-option-value-dropdown'))

    $.ajax({
      url: '/store_owners/option_type_filter',
      method: 'GET',
      dataType: 'json',
      data: {
        optionTypeID: parentDropdownValue
      }
    }).then(function (response){
      let html = ''

      $.each(response.list, function( index, value ) {
        html += '<option value="' + value[0] + '">' + value[1] + '</option>'
      });

      $final_target.html(html)
      $final_target.selectpicker('destroy')
      $final_target.selectpicker('refresh')

    })
  }
}