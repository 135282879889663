require("@fortawesome/fontawesome-free/js/all");
import "@fortawesome/fontawesome-free/css/all.css";
require("@rails/ujs").start();
require("jquery");
require("jquery-ui");
require("jquery.nicescroll");
require("moment");
require("@rails/activestorage").start();
require("channels");
require("chart.js");
require("bootstrap");
require("fullcalendar");
require("libraries/stisla/stisla.js");
require("libraries/stisla/scripts.js");
require("libraries/stisla/custom.js");
require("packs/js_pages/test_calendar.js");
require("bootstrap-select/dist/css/bootstrap-select.min.css")
require("bootstrap-select/dist/js/bootstrap-select.min")
require("bootstrap-select/js/bootstrap-select")

import "controllers"
import "bootstrap";
import 'bootstrap-select/dist/css/bootstrap-select.min.css'
import 'bootstrap-select/dist/js/bootstrap-select.min'

global.toastr = require("toastr")
import "../stylesheets/application"

window.jQuery = $;
window.$ = $;

require("trix")
require("@rails/actiontext")

global.Tablesort = require('tablesort')
require('tablesort/src/sorts/tablesort.number');

